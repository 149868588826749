import { doc, getDoc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MemberProps } from "../../../../app/modules/utils";
import { db } from "../../../../firebase-config";

interface ChangeRoleModalProps {
  show: boolean;
  setShow: (x: boolean) => void;
  roles: string | undefined;
  userId: string;
}

const roleFields = ["admin", "analyst", "billing"];

export function ChangeRoleModal({
  show,
  setShow,
  roles,
  userId,
}: ChangeRoleModalProps): JSX.Element {
  const { orgId } = useParams();
  const [changedRoles, setChangedRoles] = useState<string[]>(
    roles?.split(",") ?? []
  );

  const handleClose = () => setShow(false);

  const isChecked = (role: string) => {
    const checkedRoles = roles?.split(",");

    const checked = checkedRoles?.find((el) => el === role);

    return !!checked;
  };

  const handleChangedRoles = (role: string) => {
    setChangedRoles((prev: string[]) => {
      const exists = prev?.find((el) => el === role);
      if (exists) {
        const newRoles = prev?.filter((el) => el !== role);
        return [...newRoles];
      } else {
        return [...prev, role];
      }
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (orgId) {
      const docRef = doc(db, "organizations", orgId);
      const docSnap = await getDoc(docRef);
      const orgData = docSnap.data();

      let newOrgDataMember = orgData?.members.find(
        (el: MemberProps) => el.userId === userId
      );
      newOrgDataMember.roles = changedRoles.join(",");

      const filteredMembers = orgData?.members.filter(
        (el: MemberProps) => el.userId !== userId
      );

      await setDoc(doc(db, "organizations", orgId), {
        ...orgData,
        members: [...filteredMembers, newOrgDataMember],
      });
    }
    handleClose();
  };

  return (
    <Modal
      className="modal-sticky modal-sticky-lg modal-center d-flex justify-content-center"
      id="kt_inbox_compose"
      role="dialog"
      data-backdrop="true"
      centered={true}
      onHide={handleClose}
      aria-hidden="true"
      tabIndex="-1"
      show={show}
      animation={false}
    >
      {" "}
      <div className="p-9">
        <h1 className="p-2 mb-8">Change Users Role</h1>
        <Form onSubmit={onSubmit}>
          {roleFields?.map((role: string) => {
            return (
              <Form.Check
                key={role}
                className="mb-4"
                type="checkbox"
                id="custom-switch"
                label={role}
                onChange={() => {
                  handleChangedRoles(role);
                }}
                defaultChecked={isChecked(role)}
              />
            );
          })}
          <button className="btn btn-primary mt-8">Save Changes</button>
        </Form>
      </div>
    </Modal>
  );
}
