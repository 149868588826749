import { toAbsoluteUrl } from "../../../_metronic/helpers";

export default function LoadingPage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/logos/logo-1.svg")}
          className="h-30px mb-8"
          style={{ transform: "scale(2)" }}
        />
        <h3 style={{ fontSize: "16px" }}>Loading...</h3>
      </div>
    </div>
  );
}
