/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { auth, db } from "../../../../firebase-config";
import { useAuth } from "../core/Auth";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getOrganizationsAccess,
  removeOrganizationsAccess,
  setOrganization,
} from "../../utils";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, logout, setIsSuperUser } = useAuth();

  const loginWithGoogle = async () => {
    const google_provider = new GoogleAuthProvider();
    try {
      const res = await signInWithPopup(auth, google_provider);

      const userRef = doc(db, "users", res.user.uid);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();

      if (!userData) {
        const userOrgs = await getOrganizationsAccess(res.user.email!);

        const [firstName, lastName] = res?.user?.displayName?.split(" ") ?? [];

        const organizations = userOrgs?.map((org) => org.orgId) ?? [];

        await setDoc(doc(db, "users", res.user.uid), {
          firstName,
          lastName,
          email: res.user.email,
          isSuperUser: false,
          organizations,
          timeStamp: serverTimestamp(),
        });

        if (userOrgs) {
          for (const org of userOrgs) {
            await setOrganization(org.orgId, res.user.uid, org.roles);
          }

          for (const org of userOrgs) {
            await removeOrganizationsAccess(org.id);
          }
        }
      } else {
        setIsSuperUser(userData.isSuperUser);
      }

      if (res?.user.uid) {
        await getAllOrganizations(res?.user.uid);
      }

      setCurrentUser(res);
      const user = JSON.stringify(res);
      localStorage.setItem("currentUser", user);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllOrganizations = async (uid: string) => {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();

    if (userData) {
      if (!userData.organizations) {
        toast(
          `You don't have access to access to this screen. Contact an admin for more information`
        );
        logout();
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const res = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
        const docRef = doc(db, "users", res?.user.uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();
        if (userData) {
          setIsSuperUser(userData.isSuperUser);
        }
        setCurrentUser(res);
        const user = JSON.stringify(res);
        localStorage.setItem("currentUser", user);
        if (res?.user.uid) {
          await getAllOrganizations(res?.user.uid);
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign In to Nordic Velo</h1>
        <div className="text-gray-400 fw-bold fs-4">
          New Here?{" "}
          <Link to="/auth/registration" className="link-primary fw-bolder">
            Create an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}
      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder="Password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        <div className="text-center text-muted text-uppercase fw-bolder mb-5">
          or
        </div>
        {/* end::Separator */}

        {/* begin::Google link */}
        <button
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
          onClick={loginWithGoogle}
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
            className="h-20px me-3"
          />
          Continue with Google
        </button>
        {/* end::Google link */}
      </div>
      {/* end::Action */}
      <ToastContainer />
    </form>
  );
}
