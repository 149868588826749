import { useEffect, useState } from "react";
import { DocumentData } from "firebase/firestore";
import { Form, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { numberFormatter } from "../../modules/utils";
import {
  ExchangeFields,
  ExchangeProps,
  ExchangeExclamationProps,
  ExchangeTableProps,
} from "./type";

import styles from "./kpi-dashboard.module.scss";

function numberWithCommas(x: any) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export default function ExchangeTable({
  metric,
  filters,
  setFilters,
}: ExchangeTableProps) {
  const [exchangeInfo, setExchangeInfo] = useState<
    ExchangeProps[] | undefined
  >();
  const [marketVolumeSinglePercent, setMarketVolumeSinglePercent] =
    useState<number>(0);
  const [nvVolumeSinglePercent, setNvVolumeSinglePercent] = useState<number>(0);
  const [sort, setSort] = useState<ExchangeFields>(
    ExchangeFields.MARKET_VOLUME
  );

  const { filterByMM, filterByVolume, filterByAnomaly, filterByCEX } = filters;
  const {
    setFilterByMM,
    setFilterByVolume,
    setFilterByAnomaly,
    setFilterByCEX,
  } = setFilters;

  const getExchangeInfo = (metrics: DocumentData) => {
    if (metrics) {
      const data = [];
      let totalMarketVolume = 0;
      let totalNvVolume = 0;
      for (const key in metrics?.exchanges) {
        const exchange = metrics.exchanges[key];

        for (const tokenKey in exchange?.markets) {
          const token = exchange.markets[tokenKey];
          const isFilteredByMM = !(filterByMM && token.nv_usd_volume === 0);
          const isFilteredByAnomaly = !(filterByAnomaly && token.anomaly);
          const isFilteredByCEX = !(filterByCEX && !token.centralized);
          const isFilteredByVolume = !(
            filterByVolume &&
            (token.market_usd_volume / metrics.total_usd_volume) * 100 < 1
          );
          if (
            token &&
            isFilteredByMM &&
            isFilteredByVolume &&
            isFilteredByAnomaly &&
            isFilteredByCEX
          ) {
            totalMarketVolume += token.market_usd_volume;
            totalNvVolume += token.nv_usd_volume;
            data.push({
              exchange: key,
              pair: tokenKey,
              centralized: token.centralized,
              anomaly: token.anomaly,
              icon: exchange.icon,
              nv_volume: token.nv_usd_volume,
              market_volume: token.market_usd_volume,
              nv_market_share: token.nv_usd_market_share,
              tob_spreads: { left: "-0.85%", right: "+0.85%" },
              max_nv_volume: metrics.nv_total_usd_volume,
              max_organic_volume: metrics.total_usd_volume,
            });
          }
        }
      }
      data.sort((a: ExchangeProps, b: ExchangeProps) => b[sort] - a[sort]);
      setExchangeInfo(data);
      setMarketVolumeSinglePercent(totalMarketVolume / 100);
      setNvVolumeSinglePercent(totalNvVolume / 100);
    }
  };

  useEffect(() => {
    if (metric) {
      getExchangeInfo(metric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filterByMM, filterByVolume, filterByAnomaly, filterByCEX, metric]);

  return (
    <div className={styles.exchangeTable}>
      <div className={styles.filterSection}>
        <span>Filter:</span>
        <Form.Check
          type="checkbox"
          id="filter-by-mm"
          label="By MM"
          checked={filterByMM}
          onChange={(e) => {
            setFilterByMM(e.target.checked);
          }}
        />
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeadRow}>
              <th>Exchange</th>
              <th>Pair</th>
              <th
                onClick={() => setSort(ExchangeFields.NV_VOLUME)}
                className={styles.sortButton}
              >
                NV Volume
                {sort === ExchangeFields.NV_VOLUME ? (
                  <i className="bi bi-arrow-down"></i>
                ) : (
                  <></>
                )}
              </th>
              <th
                onClick={() => setSort(ExchangeFields.MARKET_VOLUME)}
                className={styles.sortButton}
              >
                Market Volume
                {sort === ExchangeFields.MARKET_VOLUME ? (
                  <i className="bi bi-arrow-down"></i>
                ) : (
                  <></>
                )}
              </th>
              <th
                onClick={() => setSort(ExchangeFields.NV_MARKET_SHARE)}
                className={styles.sortButton}
              >
                NV Mkt share
                {sort === ExchangeFields.NV_MARKET_SHARE ? (
                  <i className="bi bi-arrow-down"></i>
                ) : (
                  <></>
                )}
              </th>
              {/*<th>TOB spreads</th>*/}
            </tr>
          </thead>
          <tbody>
            {exchangeInfo?.map((item: ExchangeProps, index: number) => (
              <tr key={index} className={styles.exchangeRow}>
                <td>
                  <div className={styles.exchangeColumn}>
                    <img src={item.icon} alt={item.exchange} />
                    <span className={styles.exchangeText}>{item.exchange}</span>
                    {item.anomaly && (
                      <ExchangeExclamation
                        style={{ color: "red", marginRight: "3px" }}
                        text={
                          "This market has been flagged as anomalous. Market data shown for this exchange may be partially or fully incorrect or falsified"
                        }
                      />
                    )}
                    {!item.nv_volume && (
                      <ExchangeExclamation
                        text={
                          "MM is currently not being operated for this exchange"
                        }
                      />
                    )}
                  </div>
                </td>
                <td>{item.pair}</td>
                <td>
                  {numberFormatter(
                    Number((item.nv_volume / nvVolumeSinglePercent).toFixed(2))
                  )}
                  %
                  <br />
                  <ProgressBar
                    style={{ height: "8px", backgroundColor: "#E2E2EA" }}
                    variant="dark"
                    now={item.nv_volume / nvVolumeSinglePercent}
                  />
                </td>
                <td>
                  {numberFormatter(
                    Number(
                      (item.market_volume / marketVolumeSinglePercent).toFixed(
                        2
                      )
                    )
                  )}
                  % - ${numberWithCommas(item.market_volume)}
                  <br />
                  <ProgressBar
                    style={{ height: "8px", backgroundColor: "#E2E2EA" }}
                    variant="dark"
                    now={item.market_volume / marketVolumeSinglePercent}
                  />
                </td>
                <td>
                  {+Math.round(item.nv_market_share * 100 * 100) / 100}% <br />
                  <ProgressBar
                    style={{ height: "8px", backgroundColor: "#E2E2EA" }}
                    variant="dark"
                    now={item.nv_market_share * 100}
                  />
                </td>
                {/*<td className={styles.tob}>*/}
                {/*  <div className={styles.tobValues}>*/}
                {/*    <span>{item.tob_spreads.left}</span>*/}
                {/*    <span>{item.tob_spreads.right}</span>*/}
                {/*  </div>*/}
                {/*  <div className={styles.tobChart}>*/}
                {/*    <div className={styles.line}></div>*/}
                {/*    <ProgressBar*/}
                {/*      style={{ backgroundColor: "#85E13A" }}*/}
                {/*      now={50}*/}
                {/*      className={styles.tobProgress}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</td>*/}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function ExchangeExclamation({ style, text }: ExchangeExclamationProps) {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <i style={style} className="bi bi-exclamation-circle"></i>
    </OverlayTrigger>
  );
}