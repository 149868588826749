/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";

const AsideDefault: FC = () => {
  const { config, classes } = useLayout();
  const { aside } = config;

  return (
    <div
      id="kt_aside"
      className={clsx("aside", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Brand */}
      <div
        className="aside-logo flex-column-auto"
        id="kt_aside_logo"
        style={{ paddingTop: "40px" }}
      >
        {/* begin::Logo */}
        {aside.theme === "dark" && (
          <Link to="/dashboard">
            <img
              alt="Logo"
              className="h-25px logo"
              src={toAbsoluteUrl("/media/logos/logo-white.svg")}
            />
          </Link>
        )}
        {aside.theme === "light" && (
          <Link to="/dashboard">
            <img
              alt="Logo"
              className="h-25px logo"
              src={toAbsoluteUrl("/media/logos/logo-dark.svg")}
            />
          </Link>
        )}
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  );
};

export { AsideDefault };
