import React, { useEffect, useState } from "react";
import { DocumentData } from "firebase/firestore";
import { numberFormatter } from "../../modules/utils";

import styles from "./kpi-dashboard.module.scss";

export default function ExchangesBalancesSection({
  metric,
}: {
  metric: DocumentData | undefined;
}) {
  const [balancesData, setBalancesData] = useState<any>();
  const [totalBalancesData, setTotalBalancesData] = useState<any>();

  const getData = (metrics: DocumentData) => {
    if (metrics?.exchanges) {
      const exchanges = metrics.exchanges;
      const data = [];
      for (const key in exchanges) {
        const exchangeInfo: any = {};
        exchangeInfo.exchange = key;
        exchangeInfo.icon = exchanges[key].icon;
        const balances = exchanges[key]?.balances;
        if (Object.keys(balances).length) {
          exchangeInfo.balances = Object.entries(exchanges[key]?.balances);
          sortBalancesData(exchangeInfo.balances);
          data.push(exchangeInfo);
        }
      }
      setBalancesData(data);
      const totalBalances = Object.entries(metrics.total_balances);
      sortBalancesData(totalBalances);
      setTotalBalancesData(totalBalances);
    }
  };

  const sortBalancesData = (arr: any) => {
    arr?.sort(([tokenA, dataA]: any, [tokenB, dataB]: any) => {
      if (tokenA === "stable") return -1;
      if (tokenB === "stable") return 1;
      if (tokenA === "DAO") return -1;
      if (tokenB === "DAO") return 1;
      return tokenA < tokenB ? -1 : 1;
    });
  };

  useEffect(() => {
    if (metric) {
      getData(metric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric]);

  return (
    <div className={styles.exchanges}>
      <h2>Exchange balances</h2>
      <div className={styles.totalBalances}>
        {totalBalancesData?.map(([token, data]: any) => (
          <React.Fragment key={token}>
            <TotalBalanceBox
              expand={totalBalancesData.length < 4}
              titleTemplate={"Total % Balance"}
              token={token}
              data={data}
            />
          </React.Fragment>
        ))}
      </div>
      <div className={styles.exchangesBalancesSection}>
        {balancesData?.length ? (
          balancesData?.map((exchangeData: any) => (
            <CoinBox key={exchangeData.exchange} exchangeData={exchangeData} />
          ))
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "15px",
              paddingBottom: "20px",
              marginTop: "-15px",
            }}
          >
            <i
              className="bi bi-exclamation-circle"
              style={{ fontSize: "24px", color: "#daa520" }}
            ></i>
            <span>
              Due to lack of trade data. No Nordic Velo Volume can be displayed
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export const CoinBox = ({ exchangeData }: any) => {
  return (
    <div className={styles.coinBox}>
      <div className={styles.header}>
        <img src={exchangeData.icon} alt={exchangeData.exchange} />
        <span className={styles.exchangeName}>{exchangeData.exchange}</span>
      </div>
      <div className={styles.balances}>
        {exchangeData.balances &&
          exchangeData.balances.map(([token, data]: any) => (
            <React.Fragment key={token}>
              <BalanceBox token={token} data={data} />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export const TotalBalanceBox = ({ token, data, expand }: any) => {
  const title =
    token === "stable" ? "Total USD Balance" : `Total ${token} Balance`;
  const balanceStr =
    token === "stable"
      ? "$" + numberFormatter(data.balance?.toFixed(data.precision))
      : numberFormatter(data.balance?.toFixed(data.precision)) + ` ${token}`;

  return (
    <div className={`${styles.balanceBox} ${expand ? styles.expand : ""}`}>
      <h4>{title}</h4>
      <h3>{balanceStr}</h3>
    </div>
  );
};

export const BalanceBox = ({ token, data }: any) => {
  const title = token === "stable" ? "USD Balance" : `${token} Balance`;
  const balanceStr =
    token === "stable"
      ? "$" + numberFormatter(data.value?.toFixed(data.precision))
      : numberFormatter(data.value?.toFixed(data.precision)) + ` ${token}`;

  return (
    <div className={styles.balanceBox}>
      <h4>{title}</h4>
      <h3>{balanceStr}</h3>
    </div>
  );
};
