import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react"
import { toast, ToastContainer } from "react-toastify"
import { db } from "../../../firebase-config";
import { useAuth } from "../../modules/auth";

export default function OrganizationEmpty() {
    const { currentUser } = useAuth()

    const getUserInfo = async (userId: string) => {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data()
        return data
    }

    useEffect(() => {
        if (currentUser?.user.uid) {
            getUserInfo(currentUser?.user.uid).then((res) => {
                if (res && !res.organizations?.length) {
                    toast(
                        `You do not participate in any organizations`
                    );
                }
            })
        }
    }, [currentUser?.user])

    return (
        <>
            <h1>No organizations.</h1>
            <ToastContainer />
        </>
    )
}
