import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../firebase-config";
import { useAuth } from "../../modules/auth";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),

  token: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Token is required"),

  internalOrganizationName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Internal Name is required"),

  quotes: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Quotes is required"),
});

const initialValues = {
  name: "",
  token: "",
  internalOrganizationName: "",
  quotes: "",
};

export default function OrganizationsCreatePage() {
  const [loading, setLoading] = useState(false);
  const { currentUser, isSuperUser } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (isSuperUser) {
          const res = await addDoc(collection(db, "organizations"), {
            name: values.name,
            token: values.token,
            internalOrganizationName: values.internalOrganizationName,
            quotes: values.quotes.split(","),
            owner: currentUser?.user.uid,
            members: [
              { userId: currentUser?.user.uid, roles: "admin,analyst,billing" },
            ],
            timeStamp: serverTimestamp(),
          });
          if (currentUser) {
            const userRef = doc(db, "users", currentUser?.user.uid);
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();

            if (userData?.organizations) {
              const organizations = userData?.organizations;
              await setDoc(doc(db, "users", currentUser.user.uid), {
                ...userData,
                organizations: [...organizations, res.id],
              });
            }
          }
          setLoading(false);
          toast(`You have successfully created Organization`);
          navigate(`/organizations/${res.id}`);
        }
      } catch (error) {
        console.error(error);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <div className="w-lg-500px bg-white rounded shadow-sm p-8 p-lg-10 mx-auto">
      <div className="mb-8">
        <Link to="/organizations/list">
          <i className="bi bi-arrow-left fa-2x" />
        </Link>
      </div>
      <h1 className="mb-8">Create an organization</h1>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <ToastContainer />
        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Name</label>
          <input
            placeholder="Name"
            {...formik.getFieldProps("name")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.name && formik.errors.name },
              {
                "is-valid": formik.touched.name && !formik.errors.name,
              }
            )}
            type="name"
            name="name"
            autoComplete="off"
          />
          {formik.touched.name && formik.errors.name && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.name}</span>
            </div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            Internal Name
          </label>
          <input
            placeholder="Internal Name"
            {...formik.getFieldProps("internalOrganizationName")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.internalOrganizationName &&
                  formik.errors.internalOrganizationName,
              },
              {
                "is-valid":
                  formik.touched.internalOrganizationName &&
                  !formik.errors.internalOrganizationName,
              }
            )}
            type="internalOrganizationName"
            name="internalOrganizationName"
            autoComplete="off"
          />
          {formik.touched.internalOrganizationName &&
            formik.errors.internalOrganizationName && (
              <div className="fv-plugins-message-container">
                <span role="alert">
                  {formik.errors.internalOrganizationName}
                </span>
              </div>
            )}
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Token</label>
          <input
            placeholder="Token"
            {...formik.getFieldProps("token")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.token && formik.errors.token },
              {
                "is-valid": formik.touched.token && !formik.errors.token,
              }
            )}
            type="token"
            name="token"
            autoComplete="off"
          />
          {formik.touched.token && formik.errors.token && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.token}</span>
            </div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Quotes</label>
          <input
            placeholder="Quotes"
            {...formik.getFieldProps("quotes")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.quotes && formik.errors.quotes },
              {
                "is-valid": formik.touched.quotes && !formik.errors.quotes,
              }
            )}
            type="quotes"
            name="quotes"
            autoComplete="off"
          />
          {formik.touched.quotes && formik.errors.quotes && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.quotes}</span>
            </div>
          )}
        </div>
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
