import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast, ToastContainer } from "react-toastify";
import EmailSentProps from "./type";

export function InviteLink({
  show,
  setShow,
  orgId,
}: EmailSentProps): JSX.Element {
  const [email, setEmail] = useState("");
  const functions = getFunctions();
  const sendInviteLink = httpsCallable(functions, "sendInviteByEmail");

  const handleClose = () => setShow(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target),
      formDataObj = Object.fromEntries(formData.entries());
    const keys = Object.keys(formDataObj);

    if (!email) {
      return toast.error("Please fill email, subject and message");
    }

    try {
      sendInviteLink({ email, orgId, roles: keys.join() }).then(() => {
        toast.success("You have successfully invited a user!");
        handleClose();
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        className="modal-sticky modal-sticky-lg modal-center d-flex justify-content-center"
        id="kt_inbox_compose"
        role="dialog"
        data-backdrop="true"
        centered={true}
        onHide={handleClose}
        aria-hidden="true"
        tabIndex="-1"
        show={show}
        animation={false}
      >
        <div className="p-9">
          <h1 className="p-2">Send Users Invite Link</h1>
          <Form
            onSubmit={onSubmit}
            noValidate
            className="w-100 h-100  d-flex justify-content-center"
          >
            <div>
              <Form.Group>
                <input
                  className="mb-5 h-25 form-control"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Email..."
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex m-9 ">
                <Form.Group>
                  <Form.Check
                    inline
                    label="Billing"
                    name="billing"
                    type={"checkbox"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    inline
                    label="Analyst"
                    name="analyst"
                    type={"checkbox"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    inline
                    label="Admin"
                    name="admin"
                    type={"checkbox"}
                  />
                </Form.Group>
              </div>
              <Button type="submit" className="btn btn-primary w-100 p-4">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
}
