import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase-config";
import { Filters } from "../../pages/kpi-dashboard/type";
import { OrgAccessProps } from "../auth/components/types";
import { SummaryProps, Market, GMMValue } from "./type";

export interface MemberProps {
  userId: string;
  roles: string;
}

export const getCurrentOrganizationRoles = async (
  orgId: string,
  uid: string
) => {
  const orgRef = doc(db, "organizations", orgId);
  const orgSnap = await getDoc(orgRef);
  const orgData = orgSnap.data();
  const userInfo: MemberProps | undefined = orgData?.members.find(
    (el: MemberProps) => el.userId === uid
  );
  const roles = userInfo?.roles;

  return roles;
};

export const getOrgInfo = async (
  orgId: string
): Promise<DocumentData | undefined> => {
  const orgRef = doc(db, "organizations", orgId);
  const orgSnap = await getDoc(orgRef);
  const orgData = orgSnap.data();

  return orgData;
};

export const getMetricsInfo = async (
  token: string
): Promise<DocumentData | undefined> => {
  const dbUrl = `metrics_${token}`;
  console.log("Metrics check dburl", dbUrl);
  const metricsCollection = query(
    collection(db, dbUrl),
    orderBy("time", "desc"),
    limit(1)
  );
  const querySnapshot = await getDocs(metricsCollection);
  const data: DocumentData[] = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });

  return data[0];
};

export const numberFormatter = (num: number): string => {
  if (!num) return "0";
  var str = num.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

export const summaryData = (
  data: DocumentData,
  filters: Filters
): SummaryProps[] => {
  const exchanges: Market[] = Object.values(data.exchanges);

  let usdVolume = 0;
  let nvVolume = 0;
  let tokenVolume = 0;
  let spread = 0;
  let nv_depth_2 = 0;
  let nv_depth_5 = 0;

  const { filterByMM, filterByVolume, filterByAnomaly, filterByCEX } = filters;

  exchanges.forEach((value) => {
    const markets: GMMValue[] = Object.values(value.markets);
    for (let market of markets) {
      const isFilteredByMM = !(filterByMM && market.nv_usd_volume === 0);
      const isFilteredByAnomaly = !(filterByVolume && market.anomaly);
      const isFilteredByCEX = !(filterByAnomaly && !market.centralized);
      const isFilteredByVolume = !(
        filterByCEX &&
        (market.market_usd_volume / data?.total_usd_volume) * 100 < 1
      );

      if (
        market &&
        isFilteredByMM &&
        isFilteredByVolume &&
        isFilteredByAnomaly &&
        isFilteredByCEX
      ) {
        usdVolume += market.market_usd_volume;
        nvVolume += market.nv_usd_volume;
        tokenVolume += market.market_base_volume;
        nv_depth_2 += market.nv_depth_2;
        nv_depth_5 += market.nv_depth_5;
        if (market.spread) {
          spread += market.spread;
        }
      }
    }
  });

  return [
    {
      label: "Price",
      value: `$${numberFormatter(data?.price_avg)}`,
    },
    {
      label: "TOB Spread",
      value: `${(spread / exchanges.length ?? 0).toFixed(3)}%`,
    },
    {
      label: "Token Volume",
      value: numberFormatter(
        Number(tokenVolume.toFixed(data?.token_precision ?? 0))
      ),
    },
    {
      label: "USD Volume",
      value: `$${numberFormatter(Number(usdVolume.toFixed(2)))}`,
    },
    {
      label: "NV Volume",
      value: `$${numberFormatter(Number(nvVolume.toFixed(2)))}`,
     },
     {
      label: "NV Market share",
      value: `${((nvVolume / usdVolume) * 100 ?? 0).toFixed(3)}%`,
   },
    {
      label: "NV 2% depth",
      value: `$${numberFormatter(Number(nv_depth_2.toFixed(3)))}`,
    },
    {
      label: "NV 5% depth",
      value: `$${numberFormatter(Number(nv_depth_5.toFixed(3)))}`,
    },
  ];
};

export const exchangeData = (data: any) => [
  {
    exchange: {
      icon: data?.icon,
      name: data?.name,
    },
    pair: {},
  },
];

export const getOrganizationsAccess = async (
  email: string
): Promise<OrgAccessProps[]> => {
  const access = query(
    collection(db, "organizations_access"),
    where("email", "==", email)
  );
  const userDocs = await getDocs(access);
  const userOrgs: OrgAccessProps[] = [];

  userDocs.forEach((doc) => {
    if (doc.data()) {
      userOrgs.push({ ...doc.data(), id: doc.id });
    }
  });

  return userOrgs;
};

export const removeOrganizationsAccess = async (id: string) => {
  await deleteDoc(doc(db, "organizations_access", id));
};

export const setOrganization = async (
  orgId: string,
  userId: string,
  roles: string[]
) => {
  const orgRef = doc(db, "organizations", orgId);
  const orgSnap = await getDoc(orgRef);
  const orgData = orgSnap.data();

  if (orgData) {
    const members = orgData?.members ?? [];

    await setDoc(doc(db, "organizations", orgId), {
      ...orgData,
      members: [...members, { userId, roles }],
    });
  }
};
