import { useEffect, useState } from "react";
import { DocumentData } from "firebase/firestore";
import { summaryData } from "../../modules/utils";
import { SummaryProps } from "../../modules/utils/type";
import { PieChartsProps } from "./type";

import styles from "./kpi-dashboard.module.scss";

export default function SummarySection({ metric, filters }: PieChartsProps) {
  const [summaryInfo, setSummaryInfo] = useState<SummaryProps[]>();

  const getSummaryInfo = (metrics: DocumentData) => {
    if (metrics) {
      const data = summaryData(metrics, filters);
      setSummaryInfo(data);
    }
  };

  useEffect(() => {
    if (metric) {
      getSummaryInfo(metric);
    }
  }, [metric, filters]);

  return (
    <div className={styles.summary}>
      <h2>24 hr summary</h2>
      <div className={styles.summaryContainer}>
        {summaryInfo?.map((info: SummaryProps) => (
          <SummaryBox key={info.label} label={info.label} value={info.value} />
        ))}
      </div>
    </div>
  );
}

export const SummaryBox = ({ label, value }: SummaryProps) => {
  return (
    <div className={styles.summaryBox}>
      <h4>{label}</h4>
      <h3>{value}</h3>
    </div>
  );
};
