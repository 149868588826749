import { DocumentData } from "firebase/firestore";
import { useEffect, useState } from "react";
import { PieChart } from "../../../_metronic/partials/charts/chart-line-stepped/PieChart";
import { PieChartsProps } from "./type";
import styles from "./kpi-dashboard.module.scss";

const defaultData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: [],
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
};

export const PieChartsSection = ({ metric, filters }: PieChartsProps) => {
  const [organicVolumeData, setOrganicVolumeData] = useState<DocumentData>();
  const [nvVolumeData, setNvVolumeData] = useState<DocumentData>();
  const [organicVolumeOptions, setOrganicVolumeOptions] = useState(options);
  const [nvVolumeOptions, setNvVolumeOptions] = useState(options);

  const { filterByMM, filterByVolume, filterByAnomaly, filterByCEX } = filters;

  const getData = async (metrics: DocumentData) => {
    if (metrics) {
      const organicData: any = JSON.parse(
        JSON.stringify({
          ...defaultData,
        })
      );
      const nvData: any = JSON.parse(
        JSON.stringify({
          ...defaultData,
        })
      );

      const colors = [
        "001E50",
        "006C90",
        "008B98",
        "70AEA3",
        "FEE082",
        "FFEA6A",
        "FE6A2C",
        "FC3516",
        "BE1915",
      ];
      let currentColorIdx = 0;

      if (metrics?.exchanges) {
        const exchanges = metrics.exchanges;

        for (const key in exchanges) {
          const exchange = exchanges[key]?.markets;
          for (const tokenKey in exchange) {
            const token = exchange[tokenKey];
            const isFilteredByMM = !(filterByMM && token.nv_usd_volume === 0);
            const isFilteredByAnomaly = !(filterByAnomaly && token.anomaly);
            const isFilteredByCEX = !(filterByCEX && !token.centralized);
            const isFilteredByVolume = !(
              filterByVolume &&
              (token.market_usd_volume / metrics?.total_usd_volume) * 100 < 1
            );

            if (
              token &&
              isFilteredByMM &&
              isFilteredByVolume &&
              isFilteredByAnomaly &&
              isFilteredByCEX
            ) {
              organicData.datasets[0].data.push(token.market_usd_volume);
              organicData.datasets[0].backgroundColor.push(
                `#${colors[currentColorIdx]}`
              );
              organicData.labels.push(`${capitalizeWords(key)} ${tokenKey}`);
              if (token.nv_usd_volume) {
                nvData.datasets[0].data.push(token.nv_usd_volume);
                nvData.datasets[0].backgroundColor.push(
                  `#${colors[currentColorIdx]}`
                );
                nvData.labels.push(`${capitalizeWords(key)} ${tokenKey}`);
              }

              currentColorIdx =
                currentColorIdx + 1 === colors.length ? 0 : currentColorIdx + 1;
            }
          }
        }
        const opt = {
          ...options,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  if (!Array.isArray(context.label)) {
                    const label = (context.label || "") + ` ${context.raw}%`;
                    return label;
                  } else {
                    const newLabel = context.label.map(
                      (value: string, index: number) => {
                        if (index > 0) {
                          return value + "%";
                        } else {
                          return value + ` ${context.raw}%`;
                        }
                      }
                    );
                    return newLabel;
                  }
                },
              },
            },
          },
        };

        if (organicData.datasets[0].data.length >= 7) {
          setOrganicVolumeOptions(opt);
        }

        if (nvData.datasets[0].data.length >= 7) {
          setNvVolumeOptions(opt);
        }

        if (organicData.datasets[0].data.length) {
          const percentage = organicData.datasets[0].data.reduce(
            (previousValue: number, currentValue: number) =>
              previousValue + currentValue,
            0
          );

          organicData.datasets[0].data = organicData.datasets[0].data.map(
            (value: number) => Number(((value / percentage) * 100).toFixed(2))
          );

          const sorted = [...organicData.datasets[0].data];
          sorted.sort((a: number, b: number) => a - b);
          const newLabels = [];
          for (const item of sorted) {
            const index = organicData.datasets[0].data.indexOf(item);
            newLabels.push(organicData.labels[index]);
          }
          organicData.datasets[0].data = sorted;
          organicData.labels = newLabels;
        }

        if (organicData.datasets[0].data.length > 8) {
          const filteredOrganicData = organicData.datasets[0].data;
          const combinedField = ["Total"];
          let combinedPercentage = 0;
          let removableItems = [];

          for (const key in filteredOrganicData) {
            if (filteredOrganicData[key] < 3) {
              combinedPercentage += filteredOrganicData[key];
              removableItems.push(key);
              combinedField.push(
                organicData.labels[key] + " " + filteredOrganicData[key]
              );
            }
          }
          for (let i = removableItems.length - 1; i >= 0; i--) {
            filteredOrganicData.splice(removableItems[i], 1);
            organicData.labels.splice(removableItems[i], 1);
          }

          removableItems = [];
          for (const key in filteredOrganicData) {
            if (Number(key) < filteredOrganicData.length - 8) {
              combinedPercentage += filteredOrganicData[key];
              removableItems.push(key);
              combinedField.push(
                organicData.labels[key] + " : " + filteredOrganicData[key]
              );
            }
          }
          for (let i = removableItems.length - 1; i >= 0; i--) {
            filteredOrganicData.splice(removableItems[i], 1);
            organicData.labels.splice(removableItems[i], 1);
          }

          filteredOrganicData.unshift(Number(combinedPercentage.toFixed(2)));
          organicData.labels.unshift(combinedField);
        }

        if (nvData.datasets[0].data.length) {
          const percentage = nvData.datasets[0].data.reduce(
            (previousValue: number, currentValue: number) =>
              previousValue + currentValue,
            0
          );

          nvData.datasets[0].data = nvData.datasets[0].data.map(
            (value: number) => Number(((value / percentage) * 100).toFixed(2))
          );

          const sorted = [...nvData.datasets[0].data];
          sorted.sort((a: number, b: number) => a - b);
          const newLabels = [];
          for (const item of sorted) {
            const index = nvData.datasets[0].data.indexOf(item);
            newLabels.push(nvData.labels[index]);
          }
          nvData.datasets[0].data = sorted;
          nvData.labels = newLabels;

          if (nvData.datasets[0].data.length > 8) {
            const filteredNvData = nvData.datasets[0].data;
            const combinedField = ["Total"];
            let combinedPercentage = 0;
            let removableItems: string[] = [];

            for (const key in filteredNvData) {
              if (filteredNvData[key] < 3) {
                combinedPercentage += filteredNvData[key];
                removableItems.push(key);
                combinedField.push(
                  nvData.labels[key] + " " + filteredNvData[key]
                );
              }
            }
            for (let i = removableItems.length - 1; i >= 0; i--) {
              filteredNvData.splice(removableItems[i], 1);
              nvData.labels.splice(removableItems[i], 1);
            }

            removableItems = [];
            for (const key in filteredNvData) {
              if (Number(key) < filteredNvData.length - 8) {
                combinedPercentage += filteredNvData[key];
                removableItems.push(key);
                combinedField.push(
                  nvData.labels[key] + " : " + filteredNvData[key]
                );
              }
            }
            for (let i = removableItems.length - 1; i >= 0; i--) {
              filteredNvData.splice(removableItems[i], 1);
              nvData.labels.splice(removableItems[i], 1);
            }

            filteredNvData.unshift(Number(combinedPercentage.toFixed(2)));
            nvData.labels.unshift(combinedField);
          }
        }
        setOrganicVolumeData(organicData);
        setNvVolumeData(nvData);
      }
    }
  };

  const capitalizeWords = (arr: string) => {
    return arr.split(" ").map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
    });
  };

  useEffect(() => {
    if (metric) {
      getData(metric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric, filterByMM, filterByVolume, filterByAnomaly, filterByCEX]);

  return (
    <div className={styles.pieChartSection}>
      <h2>Trading volume by exchanges</h2>
      <div className={styles.charts}>
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <h3>Organic Volume by Exchange / Pairs</h3>
            <PieChart data={organicVolumeData} options={organicVolumeOptions} />
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <h3>NV volume by Exchange / Pairs</h3>
            <PieChart data={nvVolumeData} options={nvVolumeOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};
