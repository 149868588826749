import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../../../firebase-config";
import { InviteLink } from "../../../_metronic/partials/layout/inviteLink/inviteLink";
import LoadingPage from "../loading";

import styles from "../../../_metronic/partials/charts/chart-line-stepped/styles/tables.module.scss";

export default function OrganizationsList() {
  const [organizations, setOrganizations] = useState<DocumentData[]>();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentOrgId, setCurrentOrgId] = useState<string>("");

  const getAllOrganization = async () => {
    const querySnapshot = await getDocs(collection(db, "organizations"));
    const data: DocumentData[] = [];

    querySnapshot.forEach((doc) => {
      const orgData = doc.data();
      orgData.id = doc.id;
      data.push(orgData);
    });

    for (const org of data) {
      const owner = await getOrganizationOwner(org.owner);
      const date = new Date(org?.timeStamp.seconds * 1000);
      org.timeStamp = moment(date).format("MMMM d, YYYY");
      org.ownerInfo = owner;
    }
    setOrganizations(data);
    setLoading(false);
  };

  const getOrganizationOwner = async (userId: string) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    return data;
  };

  const handleOpenModal = (orgId: string) => {
    setCurrentOrgId(orgId);
    setShow(!show);
  };

  useEffect(() => {
    getAllOrganization();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Link to="/organizations/create">
            <Button className="primary mb-8">Create an organization</Button>
          </Link>
          <h1 className="mb-4">List Of Organizations</h1>
          <InviteLink show={show} setShow={setShow} orgId={currentOrgId} />
          <Table
            responsive="xl"
            variant="light"
            bordered
            hover
            striped
            className={styles.table}
          >
            <thead>
              <tr>
                <th style={{ paddingLeft: "10px" }}>#</th>
                <th>Organization Name</th>
                <th>Owner</th>
                <th>Token</th>
                <th>Timestamp</th>
                <th>Edit/Add</th>
              </tr>
            </thead>
            <tbody>
              {organizations &&
                organizations?.map((org: DocumentData, index: number) => (
                  <tr key={index}>
                    <td style={{ paddingLeft: "10px" }}>{index + 1}</td>
                    <td>{org?.name}</td>
                    <td>
                      {org?.ownerInfo.firstName} {org?.ownerInfo.lastName}
                    </td>
                    <td>{org?.token ?? "-"}</td>
                    <td>{org?.timeStamp}</td>
                    <td style={{ width: "100px" }}>
                      <Link
                        to={`/organizations/${org.id}/edit`}
                        className="text-secondary"
                      >
                        <span
                          className="text-white fw-bolder bg-primary"
                          style={{ cursor: "pointer", padding: "7px 12px" }}
                        >
                          Edit
                        </span>
                      </Link>
                      <span
                        className="text-white fw-bolder m-5 bg-success"
                        style={{
                          cursor: "pointer",
                          padding: "7px 12px",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => handleOpenModal(org.id)}
                      >
                        Invite Users
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
