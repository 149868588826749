import { useState } from "react";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import clsx from "clsx";
import * as Yup from "yup";
import { db } from "../../../firebase-config";
import { InitialValuesProps } from "./type";

interface Props {
  show: boolean;
  setShow: (e: boolean) => void;
  orgId: string;
  values: InitialValuesProps;
}

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),

  token: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Token is required"),

  internalOrganizationName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Internal Name is required"),

  quotes: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Quotes is required"),
});

export default function OrganizationEditModal({
  show,
  setShow,
  orgId,
  values,
}: Props) {
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);

  const formik = useFormik({
    initialValues: values,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const docRef = doc(db, "organizations", orgId);
        if (values) {
          await updateDoc(docRef, {
            name: values?.name,
            token: values?.token,
            internalOrganizationName: values?.internalOrganizationName,
            quotes: values?.quotes.split(","),
            timeStamp: serverTimestamp(),
          });

          setLoading(false);
          toast(`You have successfully updated Organization`);
        }
      } catch (error) {
        console.error(error);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <Modal
        className="modal-sticky modal-sticky-lg modal-center d-flex justify-content-center"
        id="kt_inbox_compose"
        role="dialog"
        data-backdrop="true"
        centered={true}
        onHide={handleClose}
        aria-hidden="true"
        tabIndex="-1"
        show={show}
        animation={false}
      >
        <div className="p-9">
          <h1 className="p-2">Edit an organization</h1>
          <form
            className="form w-100"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            <ToastContainer />
            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Name
              </label>
              <input
                placeholder="Name"
                {...formik.getFieldProps("name")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  { "is-invalid": formik.touched.name && formik.errors.name },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                type="name"
                name="name"
                autoComplete="off"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
            </div>
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Internal Name
              </label>
              <input
                placeholder="Internal Name"
                {...formik.getFieldProps("internalOrganizationName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.internalOrganizationName &&
                      formik.errors.internalOrganizationName,
                  },
                  {
                    "is-valid":
                      formik.touched.internalOrganizationName &&
                      !formik.errors.internalOrganizationName,
                  }
                )}
                type="internalOrganizationName"
                name="internalOrganizationName"
                autoComplete="off"
              />
              {formik.touched.internalOrganizationName &&
                formik.errors.internalOrganizationName && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">
                      {formik.errors.internalOrganizationName}
                    </span>
                  </div>
                )}
            </div>
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Token
              </label>
              <input
                placeholder="Token"
                {...formik.getFieldProps("token")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  { "is-invalid": formik.touched.token && formik.errors.token },
                  {
                    "is-valid": formik.touched.token && !formik.errors.token,
                  }
                )}
                type="token"
                name="token"
                autoComplete="off"
              />
              {formik.touched.token && formik.errors.token && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.token}</span>
                </div>
              )}
            </div>
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Quotes
              </label>
              <input
                placeholder="Quotes"
                {...formik.getFieldProps("quotes")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid": formik.touched.quotes && formik.errors.quotes,
                  },
                  {
                    "is-valid": formik.touched.quotes && !formik.errors.quotes,
                  }
                )}
                type="quotes"
                name="quotes"
                autoComplete="off"
              />
              {formik.touched.quotes && formik.errors.quotes && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.quotes}</span>
                </div>
              )}
            </div>
            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className="indicator-label">Continue</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}
