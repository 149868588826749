import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { UserCredential } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase-config";
import { AuthModel } from "./_models";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import * as authHelper from "./AuthHelpers";
import { getUserByToken } from "./_requests";
import { getCurrentOrganizationRoles } from "../../utils";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserCredential | undefined;
  isSuperUser: boolean;
  setCurrentUser: Dispatch<SetStateAction<UserCredential | undefined>>;
  setIsSuperUser: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
  setRole: Dispatch<SetStateAction<string | undefined>>;
  role: string | undefined;
  currentOrganization: string | undefined;
  setCurrentOrganization: Dispatch<SetStateAction<string | undefined>>;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  isSuperUser: true,
  setCurrentUser: () => {},
  setIsSuperUser: () => {},
  logout: () => {},
  setRole: () => {},
  role: undefined,
  currentOrganization: undefined,
  setCurrentOrganization: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserCredential | undefined>();
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  const [currentOrganization, setCurrentOrganization] = useState<
    string | undefined
  >();
  const [role, setRole] = useState<string | undefined>();

  const localStorageUser = localStorage.getItem("currentUser");

  useEffect(() => {
    if (localStorageUser) {
      setCurrentUser(JSON.parse(localStorageUser));
    }
  }, [localStorageUser]);

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    setIsSuperUser(false);
    localStorage.setItem("currentUser", "");
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        isSuperUser,
        setIsSuperUser,
        role,
        setRole,
        currentOrganization,
        setCurrentOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC = ({ children }) => {
  const {
    auth,
    logout,
    setRole,
    setCurrentUser,
    currentUser,
    setIsSuperUser,
    setCurrentOrganization,
  } = useAuth();

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        const user = localStorage.getItem("currentUser");
        if (user) {
          setCurrentUser(JSON.parse(user));
        } else if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken);
          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.api_token) {
      requestUser(auth.api_token);
    } else {
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  const getUserData = async (uid: string) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    const userData = docSnap.data();
    if (userData) {
      setIsSuperUser(userData?.isSuperUser);
      if (userData?.organizations.length && currentUser) {
        const roles = await getCurrentOrganizationRoles(
          userData?.organizations[0],
          currentUser?.user.uid
        );
        setRole(roles);
      }
      setCurrentOrganization(userData?.organizations[0]);
    }
  };

  useEffect(() => {
    if (currentUser?.user?.uid) {
      getUserData(currentUser.user.uid);
    }
  }, [currentUser]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
