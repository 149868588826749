/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { DocumentData } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getOrgInfo } from "../../../../app/modules/utils";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { DefaultTitle } from "./page-title/DefaultTitle";
import { Topbar } from "./Topbar";

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout();
  const { orgId } = useParams();
  const [orgInfo, setOrgInfo] = useState<DocumentData>();
  const location = useLocation();
  const isKpiPage = location.pathname.includes("kpi-dashboard");
  const { header, aside } = config;

  const getCurrentOrgInfo = async (orgId: string) => {
    const data = await getOrgInfo(orgId);
    setOrgInfo(data);
  };

  useEffect(() => {
    if (orgId) {
      getCurrentOrgInfo(orgId);
    }
  }, [orgId]);

  return (
    <div
      id="kt_header"
      className={clsx(
        "header",
        classes.header.join(" "),
        "align-items-stretch"
      )}
      style={{ backgroundColor: "#F6F5F8", height: "100px", zIndex: 101 }}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-stretch justify-content-between"
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <KTSVG
                path="/media/icons/duotune/abstract/abs015.svg"
                className="svg-icon-2x mt-1"
              />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/dashboard" className="d-lg-none">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-1.svg")}
                className="h-30px"
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {/* {aside.display && ( */}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/" className="d-lg-none">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              className="h-30px"
            />
          </Link>
        </div>
        {/* )} */}

        {/* begin::Wrapper */}
        <div className="d-flex align-items-center justify-content-between flex-lg-grow-1">
          {/* begin::Navbar */}

          {header.left === "page-title" && (
            <div className="d-flex align-items-center" id="kt_header_nav">
              <DefaultTitle />
            </div>
          )}
          <div>
            {isKpiPage && orgInfo && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "22px",
                }}
              >
                <div
                  style={{
                    width: "54px",
                    height: "54px",
                    background:
                      "linear-gradient(180deg, #90F784 0%, #0AD0F3 100%)",
                    borderRadius: "4px",
                  }}
                ></div>
                <h4 style={{ fontSize: "24px", marginBottom: 0 }}>
                  {orgInfo?.name}
                </h4>
              </div>
            )}
          </div>

          <div className="d-flex align-items-stretch flex-shrink-0">
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
}
