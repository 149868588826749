import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Spinner } from "react-bootstrap";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function PieChart({ data, options }: { data?: any; options?: any }) {
  return data ? (
    <Pie options={options} data={data} />
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner animation="border" />
    </div>
  );
}
