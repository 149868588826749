import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth } from "../../../../app/modules/auth";
import { db } from "../../../../firebase-config";
import { getCurrentOrganizationRoles } from "../../../../app/modules/utils";

interface OrganizationsProps extends DocumentData {
  id: string;
}

export function AsideMenuMain() {
  const {
    isSuperUser,
    currentUser,
    setCurrentOrganization,
    currentOrganization,
    setRole,
    role,
  } = useAuth();
  const [memberOrgs, setMemberOrgs] = useState<OrganizationsProps[]>([]);
  const navigate = useNavigate();

  const getAllOrganizations = async (uid: string) => {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    const data: OrganizationsProps[] = [];

    if (userData?.organizations) {
      for (const orgId of userData?.organizations) {
        const orgRef = doc(db, "organizations", orgId);
        const orgSnap = await getDoc(orgRef);
        const orgData = orgSnap.data();
        if (orgData) {
          data.push({ ...orgData, id: orgId });
        }
      }
    }
    setMemberOrgs(data);
  };

  const onSetCurrentOrg = async (e: ChangeEvent<HTMLSelectElement>) => {
    const orgId = e.target.value;
    if (orgId) {
      if (isSuperUser) {
        setRole("admin,analyst,billing");
      } else if (currentUser) {
        const roles = await getCurrentOrganizationRoles(
          orgId,
          currentUser.user.uid
        );
        setRole(roles);
      }

      setCurrentOrganization(orgId);
      navigate(`/organizations/${orgId}`);
    }
  };

  useEffect(() => {
    if (currentUser?.user?.uid) {
      getAllOrganizations(currentUser?.user.uid);
    }
  }, [currentUser]);

  return (
    <>
      <div className="p-8">
        {memberOrgs &&
          (isSuperUser ? (
            <Form.Select
              id="dropdown-basic-button"
              className="p-4"
              aria-label="Organizations"
              onChange={onSetCurrentOrg}
            >
              {memberOrgs.map((org: DocumentData) => (
                <option key={org.name} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Form.Select>
          ) : (
            <div
              style={{
                backgroundColor: "#fff",
                padding: "16px",
                borderRadius: "8px",
                fontSize: "16px",
              }}
            >
              {memberOrgs[0]?.name}
            </div>
          ))}
      </div>
      {isSuperUser && (
        <>
          <AsideMenuItem
            to="/organizations/list"
            icon="/media/icons/duotune/general/gen025.svg"
            title="Super User"
            fontIcon="bi-layers"
          />
          <hr
            style={{
              color: "white",
              margin: "0 0 10px 0",
            }}
          />
        </>
      )}
      {/* {memberOrgs.length && currentOrganization && (
        <AsideMenuItem
          to={`/organizations/${currentOrganization}`}
          icon="/media/icons/duotune/general/trade.svg"
          title="Marketing"
          fontIcon="bi-layers"
        />
      )} */}
      {(isSuperUser || (memberOrgs.length && currentOrganization &&  (role?.includes("analyst") || role?.includes("admin"))) )&& (
        <AsideMenuItem
          to={`/kpi-dashboard/${currentOrganization}`}
          icon="/media/icons/duotune/general/trade.svg"
          title="KPI dashboard"
          fontIcon="bi-layers"
        />
      )}
      {role?.includes("admin") && (
        <AsideMenuItem
          to={`/organizations/${currentOrganization}/userManagement`}
          icon="/media/icons/duotune/general/gen025.svg"
          title="User Management"
          fontIcon="bi-layers"
        />
      )}
      {role?.includes("billing") && (
        <AsideMenuItem
          to={`/organizations/${currentOrganization}/billing`}
          icon="/media/icons/duotune/general/gen025.svg"
          title="Billing"
          fontIcon="bi-layers"
        />
      )}
      {role?.includes("analyst") && (
        <AsideMenuItem
          to={`/organizations/${currentOrganization}/reports`}
          icon="/media/icons/duotune/general/gen025.svg"
          title="Reports"
          fontIcon="bi-layers"
        />
      )}
    </>
  );
}
