import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { useAuth } from "../modules/auth";
import { MenuTestPage } from "../pages/MenuTestPage";
import OrganizationsCreatePage from "../pages/organizations/OrganizationsCreatePage";
import Reports from "../pages/reports";
import Billing from "../pages/billing";
import { Marketing } from "../pages/charts/Charts";
import UserManagement from "../pages/userManagement";
import OrganizationsList from "../pages/organizations/OrganizationsList";
import OrganizationUsers from "../pages/organizations/OrganizationUser";
import OrganizationInvitePage from "../pages/organizations/OrganizationInvitePage";
import { db } from "../../firebase-config";
import OrganizationEmpty from "../pages/organizations/OrganizationEmpty";
import { LayoutSplashScreen } from "../../_metronic/layout/core";
import { KpiDashboard } from "../pages/kpi-dashboard";

const PrivateRoutes = () => {
  const { isSuperUser, role, currentUser } = useAuth();
  const [userInfo, setUserInfo] = useState<DocumentData>();
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);

  const getUserInfo = async (userId: string) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    setUserInfo(data);
  };

  useEffect(() => {
    if (currentUser?.user.uid) {
      getUserInfo(currentUser?.user.uid).then(() => {
        setShowSplashScreen(false);
      });
    }
  }, [currentUser]);

  return showSplashScreen ? (
    <LayoutSplashScreen />
  ) : (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to={"/dashboard"} />} />
        {/* Pages */}
        <Route
          path="dashboard"
          element={
            isSuperUser ? (
              <Navigate to={"/organizations/list"} />
            ) : userInfo?.organizations && userInfo?.organizations.length ? (
              <Navigate to={`/organizations/${userInfo?.organizations[0]}`} />
            ) : (
              <OrganizationEmpty />
            )
          }
        />
        <Route path="/kpi-dashboard/:orgId" element={<KpiDashboard />} />
        {isSuperUser && (
          <>
            <Route
              path="organizations/create"
              element={<OrganizationsCreatePage />}
            />
            <Route path="organizations/list" element={<OrganizationsList />} />
            <Route
              path="organizations/:orgId/edit"
              element={<OrganizationUsers />}
            />
          </>
        )}
        {(isSuperUser || userInfo?.organizations) && (
          <Route path="organizations/:orgId" element={<KpiDashboard />} />
        )}
        <Route
          path="organizations/:orgId/invite/:roles"
          element={<OrganizationInvitePage />}
        />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {role?.includes("analyst") && (
          <Route path="organizations/:orgId/reports" element={<Reports />} />
        )}
        {role?.includes("billing") && (
          <Route path="organizations/:orgId/billing" element={<Billing />} />
        )}
        {role?.includes("admin") && (
          <Route
            path="organizations/:orgId/userManagement"
            element={<UserManagement />}
          />
        )}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
