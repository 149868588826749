import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { doc, DocumentData, getDoc, setDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import { db } from "../../../firebase-config";
import { ChangeRoleModal } from "../../../_metronic/partials/layout/change-role";
import { InitialValuesProps } from "./type";
import { MemberProps } from "../../modules/utils";
import LoadingPage from "../loading";
import OrganizationEditModal from "./OrganizationEditModal";

import styles from "../../../_metronic/partials/charts/chart-line-stepped/styles/tables.module.scss";

interface UserInfo extends DocumentData {
  roles: string;
  userId: string;
}

export default function OrganizationUsers(): JSX.Element {
  const { orgId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showOrgEdit, setShowOrgEdit] = useState<boolean>(false);
  const [orgInfo, setOrgInfo] = useState<DocumentData>();
  const [values, setValues] = useState<InitialValuesProps>();

  const getUsers = async () => {
    const docRef = doc(db, "organizations", orgId!);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const userData: UserInfo[] = [];
    for (const member of data?.members) {
      const userRef = doc(db, "users", member.userId);
      const userSnap = await getDoc(userRef);
      const data = userSnap.data();
      if (data) {
        userData.push({ ...data, roles: member.roles, userId: member.userId });
      }
    }
    setUsers(userData);
    setLoading(false);
  };

  const getOrgInfo = async (orgId: string) => {
    const docRef = doc(db, "organizations", orgId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (data) {
      setOrgInfo(data);
    }
  };

  useEffect(() => {
    if (orgId) {
      getUsers();
      getOrgInfo(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    setValues({
      name: orgInfo?.name,
      token: orgInfo?.token,
      internalOrganizationName: orgInfo?.internalOrganizationName,
      quotes: orgInfo?.quotes?.join(","),
    });
  }, [orgInfo]);

  const setOpenModal = () => {
    setShow(!show);
  };

  const onRemoveUser = async (userId: string) => {
    if (orgId) {
      const docRef = doc(db, "organizations", orgId);
      const docSnap = await getDoc(docRef);
      const orgData = docSnap.data();

      const filteredMembers = orgData?.members.filter(
        (el: MemberProps) => el.userId !== userId
      );

      await setDoc(doc(db, "organizations", orgId), {
        ...orgData,
        members: filteredMembers,
      });

      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();
      if (userData) {
        const organizations = userData?.organizations.filter(
          (org: string) => org !== orgId
        );

        await setDoc(doc(db, "users", userId), {
          ...userData,
          organizations: organizations,
        });
      }

      setUsers((prev: UserInfo[]) => {
        const newUsers = prev.filter((el) => el.userId !== userId);
        return newUsers;
      });
      toast.success("You have successfully removed a user!");
    }
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/organizations/list" className="btn btn-dark mb-8">
              <i className="bi bi-arrow-left fa-2x" />
            </Link>
            <Button variant="primary" onClick={() => setShowOrgEdit(true)}>
              Edit Organization
            </Button>
          </div>
          {values && (
            <OrganizationEditModal
              show={showOrgEdit}
              setShow={setShowOrgEdit}
              orgId={orgId ?? ""}
              values={values}
            />
          )}
          <Table
            responsive="md"
            variant="light"
            striped
            bordered
            hover
            className={styles.table}
          >
            <thead>
              <tr>
                <th style={{ paddingLeft: "10px" }}>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Roles</th>
                <th>Remove/Change Role</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((item: UserInfo, index: number) => (
                <tr key={index}>
                  <td style={{ paddingLeft: "10px" }}>{index + 1}</td>
                  <td>{item?.firstName}</td>
                  <td>{item?.lastName}</td>
                  <td>{item?.email}</td>
                  <td>{item?.roles}</td>
                  <td style={{ width: "180px" }}>
                    <div style={{ columnGap: "10px", display: "flex" }}>
                      <span
                        onClick={() => onRemoveUser(item.userId)}
                        className="bi bi-trash text-danger"
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          lineHeight: "14px",
                        }}
                      ></span>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          lineHeight: "14px",
                        }}
                        onClick={setOpenModal}
                        className="bi bi-pen text-primary"
                      ></span>
                    </div>
                  </td>
                  <ChangeRoleModal
                    show={show}
                    setShow={setShow}
                    roles={item.roles}
                    userId={item.userId}
                  />
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <ToastContainer />
    </>
  );
}
