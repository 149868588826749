import { HTMLAttributes } from "react";
import { DocumentData } from "firebase/firestore";

export interface ExchangeProps {
  exchange: string;
  pair: string;
  icon: string;
  nv_volume: number;
  market_volume: number;
  nv_market_share: number;
  anomaly: boolean;
  centralized: boolean;
  tob_spreads: any;
  max_nv_volume: number;
  max_organic_volume: number;
}

export enum ExchangeFields {
  MARKET_VOLUME = "market_volume",
  NV_VOLUME = "nv_volume",
  NV_MARKET_SHARE = "nv_market_share",
}

export interface ExchangeExclamationProps extends HTMLAttributes<HTMLElement> {
  text: string;
}

export interface PieChartsProps {
  metric: DocumentData | undefined;
  filters: Filters;
}

export interface Filters {
  filterByMM: boolean;
  filterByVolume: boolean;
  filterByAnomaly: boolean;
  filterByCEX: boolean;
}

export interface ExchangeTableProps extends PieChartsProps {
  setFilters: SetFilters;
}

interface SetFilters {
  setFilterByMM: (arg: boolean) => void;
  setFilterByVolume: (arg: boolean) => void;
  setFilterByAnomaly: (arg: boolean) => void;
  setFilterByCEX: (arg: boolean) => void;
}
