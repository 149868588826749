import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { useAuth } from '../../modules/auth';
import { UserInfo } from './type';

export default function OrganizationInvitePage() {
    const { orgId, roles } = useParams()
    const { currentUser } = useAuth()
    const navigate = useNavigate();

    const addUserToOrganization = async () => {
        if (orgId) {
            const docRef = doc(db, "organizations", orgId);
            const docSnap = await getDoc(docRef);
            const orgData = docSnap.data()

            const newMember = {
                roles,
                userId: currentUser?.user.uid
            }

            if (orgData?.members && currentUser?.user) {
                const members = orgData?.members
                const isOrgMember = members.find((el: UserInfo) => el.userId === currentUser?.user.uid)

                if (!isOrgMember) {
                    await setDoc(doc(db, "organizations", orgId), {
                        ...orgData,
                        members: [
                            ...members,
                            newMember
                        ]
                    });
                    const userRef = doc(db, "users", currentUser?.user.uid);
                    const userSnap = await getDoc(userRef);
                    const userData = userSnap.data()

                    if (userData?.organizations) {
                        const organizations = userData?.organizations
                        await setDoc(doc(db, "users", currentUser.user.uid), {
                            ...userData,
                            organizations: [
                                ...organizations,
                                orgId
                            ]
                        });
                    }
                }
                return members
            }

        }
    }

    useEffect(() => {
        addUserToOrganization().then((res) => {
            if (res) {
                navigate(`/organizations/${orgId}`, { replace: true })
            }
        })
    })

    return (
        <h1>You have successfully joined the organization!</h1>
    )
}
